import { css } from "styled-components"
import { colors, easing } from "./variables"
import "@reach/listbox/styles.css"

export default css`
  /* Dialog */
  :root {
    --reach-dialog: 1;
  }

  [data-reach-dialog-overlay] {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (min-width: 33em) {
    [data-reach-dialog-overlay] {
      animation: fade-in 300ms ${easing.standard} forwards;
    }
  }

  /* Accordion */
  :root {
    --reach-accordion: 1;
  }

  [data-reach-accordion-panel] {
    outline: none;
  }

  [data-reach-accordion-button][data-disabled] {
    cursor: not-allowed;
  }

  /* Reach Combox */
  :root {
    --reach-combobox: 1;
  }

  [data-reach-combobox-popover] {
    border: solid 1px hsla(0, 0%, 0%, 0.25);
    background: hsla(0, 100%, 100%, 0.99);
    font-size: 85%;
  }

  [data-reach-combobox-list] {
    list-style: none;
    margin: 0;
    padding: 0;
    user-select: none;
  }

  [data-reach-combobox-option] {
    cursor: pointer;
    margin: 0;
    padding: 1.3rem 2rem;
    border-radius: 0.6rem;
    margin: 0.5rem;
  }

  [data-reach-combobox-option][aria-selected="true"] {
    background: #0075c9;
    color: white;
  }

  [data-reach-combobox-option]:hover {
    background: rgba(28, 146, 247, 0.1);
  }

  [data-reach-combobox-option][aria-selected="true"]:hover {
    background: rgba(28, 146, 247, 0.1);
    color: black;
  }

  [data-user-value] {
    font-weight: bold;
  }

  /* Listbox */
  [data-reach-listbox-button] {
    border: 1px solid #c4c4c4;
    font-size: 1.8rem;
    border-radius: 1rem;
    width: 100%;
    padding: 2rem 2rem 2.1rem 2rem;
    display: block;
    min-height: 67px;

    &:focus {
      outline: 0;
      border: 1px solid ${colors.blue};
      box-shadow: 0 0 0 1px ${colors.blue};
    }
  }

  [data-reach-listbox-button].is-invalid {
    border: 2px solid red;
  }

  [data-reach-listbox-list] {
    max-height: 40vh;
    overflow-y: auto;
  }
`
