exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-evenemang-js": () => import("./../../../src/pages/evenemang.js" /* webpackChunkName: "component---src-pages-evenemang-js" */),
  "component---src-pages-export-js": () => import("./../../../src/pages/export.js" /* webpackChunkName: "component---src-pages-export-js" */),
  "component---src-pages-hitta-traening-js": () => import("./../../../src/pages/hitta-traening.js" /* webpackChunkName: "component---src-pages-hitta-traening-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspiration-js": () => import("./../../../src/pages/inspiration.js" /* webpackChunkName: "component---src-pages-inspiration-js" */),
  "component---src-pages-languages-js": () => import("./../../../src/pages/languages.js" /* webpackChunkName: "component---src-pages-languages-js" */),
  "component---src-pages-om-parame-js": () => import("./../../../src/pages/om-parame.js" /* webpackChunkName: "component---src-pages-om-parame-js" */),
  "component---src-pages-registrera-js": () => import("./../../../src/pages/registrera.js" /* webpackChunkName: "component---src-pages-registrera-js" */),
  "component---src-pages-sok-js": () => import("./../../../src/pages/sok.js" /* webpackChunkName: "component---src-pages-sok-js" */),
  "component---src-pages-sponsorer-js": () => import("./../../../src/pages/sponsorer.js" /* webpackChunkName: "component---src-pages-sponsorer-js" */),
  "component---src-pages-traena-hemma-js": () => import("./../../../src/pages/traena-hemma.js" /* webpackChunkName: "component---src-pages-traena-hemma-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-association-js": () => import("./../../../src/templates/association.js" /* webpackChunkName: "component---src-templates-association-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-exercise-video-js": () => import("./../../../src/templates/exercise-video.js" /* webpackChunkName: "component---src-templates-exercise-video-js" */),
  "component---src-templates-find-association-js": () => import("./../../../src/templates/find-association.js" /* webpackChunkName: "component---src-templates-find-association-js" */),
  "component---src-templates-info-js": () => import("./../../../src/templates/info.js" /* webpackChunkName: "component---src-templates-info-js" */),
  "component---src-templates-sport-js": () => import("./../../../src/templates/sport.js" /* webpackChunkName: "component---src-templates-sport-js" */)
}

