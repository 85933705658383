import React, { createContext, useReducer } from "react"

const reducer = (state, action) => {
  let newState

  switch (action.type) {
    case "set:place":
      newState = {
        ...state,
        place: action.payload,
        secondaryPlace: action.payload,
      }
      break

    case "set:region":
      newState = {
        ...state,
        region: action.payload,
        secondaryRegion: action.payload,
      }
      break

    case "set:coordinates":
      newState = {
        ...state,
        coordinates: action.payload,
        secondaryCoordinates: action.payload,
      }
      break
    case "set:disability":
      newState = {
        ...state,
        disability: action.payload,
      }
      break
    case "set:categories":
      newState = {
        ...state,
        categories: action.payload,
      }
      break
    case "set:tag":
      newState = {
        ...state,
        tag: action.payload,
      }
      break
    case "set:secondary-place":
      newState = {
        ...state,
        secondaryPlace: action.payload,
      }
      break

    case "set:secondary-region":
      newState = {
        ...state,
        secondaryRegion: action.payload,
      }
      break

    case "set:secondary-coordinates":
      newState = {
        ...state,
        secondaryCoordinates: action.payload,
      }
      break
    case "set:show-quicksearch":
      newState = {
        ...state,
        showQuickSearch: action.payload,
      }
      break
    case "set:language":
      newState = {
        ...state,
        language: action.payload,
      }
      break
    default:
      newState = { ...state }
  }

  const stateToStore = { ...newState }
  delete stateToStore.showQuickSearch

  sessionStorage.setItem("state", JSON.stringify(stateToStore))

  return newState
}

let initialState = {
  place: "",
  region: null,
  coordinates: null,
  disability: null,
  categories: [],
  tag: null,
  secondaryPlace: "",
  secondaryRegion: "",
  secondaryCoordinates: null,
  showQuickSearch: false,
  language: "",
}

if (typeof window !== "undefined") {
  const storedState = sessionStorage.getItem("state")

  if (storedState) {
    initialState = {
      ...initialState,
      ...JSON.parse(storedState),
    }
  }
}

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
}

export const Context = createContext(initialState)
export default Provider
