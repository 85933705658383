export const colors = {
  blue: "#0075C9",
  blueDark: "#00234E",
  fucsia: "#F41251",
  greyLight: "#D8D8D8",
  yellow: "#FEE23C",
  purple: "#5236B3",
  darkPurple: "#1a0076",
  azureRadiance: "#108FEA",
}

export const easing = {
  standard: "cubic-bezier(0.4, 0.0, 0.2, 1)",
  decelerate: "cubic-bezier(0.0, 0.0, 0.2, 1)",
  accerlerate: "cubic-bezier(0.4, 0.0, 1, 1)",
}

export const site = {
  width: 1480,
  gutter: {
    s: 20,
    l: 40,
  },
}

export const fontSizes = {
  headingSmall: "2.4rem",
  heading: "3.6rem",
}
