const React = require("react")
const Provider = require("./src/store").default

require("typeface-open-sans")
require("typeface-righteous")

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }

  if (typeof URLSearchParams == "undefined") {
    await import("url-search-params-polyfill")
  }

  const smoothscroll = await import("smoothscroll-polyfill")
  smoothscroll.default.polyfill()
}

export const wrapRootElement = ({ element }) => <Provider>{element}</Provider>

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation !== null && location.pathname !== prevLocation.pathname) {
    const heading = document.querySelector("h1")

    if (heading) {
      heading.focus()
    }
  }
}
