import { createGlobalStyle } from "@nfront/global-styles"
import sanitize from "./sanitize"
import reach from "./reach"
import { minify } from "csso"
import { colors } from "./variables"

const { css } = minify(`
  ${sanitize}
  ${reach}

  html {
    font-size: 62.5%;
  }

  body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.778;
    font-size: 1.8rem;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  a {
    color: ${colors.blue};
    text-decoration: none;
    transition: color 200ms;
  }

  a:hover {
    color: #004779;
  }

  h1 {
    outline: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700;
    margin: 0;
    line-height: 1.25;
  }

  ul, ol {
    list-style: none;
  }

  p, ul {
    margin: 0 0 1.778em;
  }

  button {
    cursor: pointer;
  }

  input, textarea {
    font-family: "Open Sans", sans-serif;
  }

  // img[alt=""] {
  //   border: 2px red dashed !important;
  // }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
  }

  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 0.001s !important;
      transition-duration: 0.001s !important;
      animation-delay: 0s !important;
      transition-delay: 0s !important;
    }
  }
`)

export default createGlobalStyle`${css}`
